<template>
    <div>
        <div class="commonInputTopBox">
            <pageHeader :pagename="'工单大厅 '" :total="queryData.total" />

            <CommonHeaders
                :formConfig="formConfig"
                :formModel="formModel"
                :buttons="buttons"
                @button-click="handleButtonClick"
            ></CommonHeaders>
        </div>

        <div class="layoutTabaleBorder">
            <el-table :data="workOrderData" style="width: 100%" align="center">
                <el-table-column
                    prop="garage_id"
                    label="车库id"
                ></el-table-column>
                <el-table-column
                    prop="ticket_number"
                    label="工单编号"
                    min-width="180"
                >
                </el-table-column>
                <el-table-column prop="order_type" label="工单类型">
                </el-table-column>

                <el-table-column
                    prop="issue_description"
                    label="工单描述"
                    width="400"
                >
                </el-table-column>

                <el-table-column prop="status" label="状态"> </el-table-column>

                <el-table-column prop="priority" label="故障等级">
                </el-table-column>

                <el-table-column
                    label="操作"
                    align="center"
                    fixed="right"
                    width="240"
                >
                    <template slot-scope="scope">
                        <el-button type="info" @click="toInfo(scope.row)"
                            >详情</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <div class="layoutBorder">
            <Pagination
                :total="queryData.total"
                :page.sync="queryData.currentPage"
                @pagination="getList"
                :limit.sync="queryData.pageSize"
            />
        </div>
    </div>
</template>

<script>
import Pagination from '@/components/Pagination.vue';
import CommonHeaders from '@/components/shibo_Common/CommonHeaders.vue';

import { work_order_query } from '@/api/manage/operation/work_order/work_order.js';

export default {
    name: 'work_order',
    data() {
        return {
            workOrderData: [],
            queryData: {
                currentPage: 1,
                total: 0,
                pageCount: 0,
                pageSize: 10
            },
            // 过滤器组件信息
            formConfig: [
                {
                    label: '关键字',
                    prop: 'keyword',
                    component: 'el-input',
                    placeholder: '请输入关键词'
                },
                {
                    label: '工单类型',
                    prop: 'status',
                    component: 'el-select',
                    placeholder: '请选择故障等级',
                    clearable: true,
                    options: [
                        { label: '故障工单', value: '1' },
                        { label: '客户工单', value: '2' }
                    ]
                },
                {
                    label: '故障等级',
                    prop: 'priority',
                    component: 'el-select',
                    placeholder: '请选择故障等级',
                    clearable: true,
                    options: [
                        { label: '普通', value: '1' },
                        { label: '中等', value: '2' },
                        { label: '最高', value: '3' }
                    ]
                },
                {
                    label: '工单状态',
                    prop: 'status',
                    component: 'el-select',
                    placeholder: '请选择故障等级',
                    clearable: true,
                    options: [
                        { label: '未解决', value: '1' },
                        { label: '已解决', value: '2' }
                    ]
                }
            ],
            formModel: {},
            buttons: [{ label: '查询', type: 'primary', action: 'search' }]
        };
    },
    components: {
        Pagination,
        CommonHeaders
    },
    created() {
        this.getList(); // 定义该函数在页面一加载时就执行
    },
    methods: {
        // 获取接口数据的方法
        getList() {
            let payload = {
                ...this.formModel,
                querypage: this.queryData.currentPage,
                pagesize: this.queryData.pageSize
            };
            work_order_query(payload).then(res => {
                this.workOrderData = res.data.result;
                console.log(this.workOrderData);
            });
        },
        // 头部组件按钮事件
        handleButtonClick(button) {
            if (button.action === 'search') {
                this.getList();
            }
        },
        toInfo(row) {
            console.log(row);
            this.$router.push({
                name: 'work_Info',
                query: {
                    ticket_number: row.ticket_number
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped></style>
